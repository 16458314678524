<template>
  <div class="container">
    <div v-if="loadingPage" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>
    <div v-else class="written-exam-courses-list mt-4">
      <h4 class="text-center">Список курсов (письменный экзамен)</h4>


      <!-- Table Start -->
      <DataTable :value="writtenExamCoursesList_form.writtenExamCoursesInfos" :paginator="true" :rows="50"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[50, 100, 200]"
                 filterDisplay="menu"
                 :globalFilterFields="['course_name']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск курса"/>
              </span>
          </div>
        </template>


        <Column field="course_name" header="Название курса"></Column>
        <Column header="Группа">
          <template #body="{data}">
            {{ data.studentGroups ? data.studentGroups?.name : data.selectableGroup?.name }}
          </template>
        </Column>
        <Column field="educationCurriculum.study_course" header="Курс"></Column>
        <Column field="formationEducationProgram.semester" header="Семестр"></Column>
        <Column field="formationEducationProgram.educationDiscipline.language.native_name"
                header="Язык обучения"></Column>
        <Column header="Лектор">
          <template #body="{data}">
            {{ data.lecturePpsData.lastname }} {{ data.lecturePpsData.firstname }}
          </template>
        </Column>

        <Column header="Распечатать билеты">
          <template #body="{data}">
            <a :href="`https://back.uib.kz/exam_print/print-questions-by-group/print-questions?${data.student_group_id?`group_id=${data.student_group_id}&`:``}mdl_course_id=${data.mdl_course_id}`" target="_blank">
              Посмотреть
            </a>
            <Button icon="pi pi-print"
                    @click="confirmExamQuestionsSendToPrint(data.student_group_id, data.mdl_course_id)"/>
          </template>
        </Column>


      </DataTable>
      <!-- Table End -->


    </div>
    <ConfirmDialog></ConfirmDialog>


  </div>
</template>

<script>
  import {FilterMatchMode, FilterOperator} from 'primevue/api';
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";


  export default {
    name: "WrittenExamCoursesList",
    data() {
      return {
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'course_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'studentGroups.name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'educationProgram.education_speciality_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },

          'studyLevel.name': {value: null, matchMode: FilterMatchMode.EQUALS},
          'studyLanguage.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},
          'education_program_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        },
        studyCourses: [
          '1', '2', '3', '4'
        ],
        studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
          'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)'],
        studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
        educationPrograms: ['0'],
        loadingPage: true
      }
    },
    computed: {
      ...mapState('writtenExamCoursesList', ['writtenExamCoursesList_form']),
      ...mapState('vacancy', ['departments']),
    },

    methods: {
      ...mapActions('writtenExamCoursesList', ['GET_EDUCATION_COURSES_WRITTEN_EXAM', 'GET_EXAM_QUESTIONS_SEND_TO_PRINT']),
      ...mapActions('vacancy', ['GET_DEPARTMENTS']),

      confirmExamQuestionsSendToPrint(group_id, mdl_course_id) {
        const groupId = group_id ? `group_id=${group_id}&` : ``
        let url = `https://back.uib.kz/exam_print/print-questions-by-group/print-questions?${groupId}mdl_course_id=${mdl_course_id}`
        console.log(url, 'url')
        this.$confirm.require({
          message: 'Вы уверены что хотите отправить на печать?',
          header: 'Потверждение',
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            const res = await this.GET_EXAM_QUESTIONS_SEND_TO_PRINT(url)
            console.log(res, "RES")
            if (res == 1) {
              this.$toast.add({severity:'info', summary:'Потвержден', detail:'Успешно.', life: 3000});
            } else {
              this.$toast.add({severity:'error', summary:'Ошибка', detail: 'Произошла ошибка', life: 3000});
            }
          },
          reject: () => {
          }
        });
      },

    },
    async mounted() {
      await this.GET_DEPARTMENTS()
      await this.GET_EDUCATION_COURSES_WRITTEN_EXAM()
      this.loadingPage = false;
    }
  }
</script>

<style scoped>

</style>